exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-e-mobilitaet-js": () => import("./../../../src/pages/e-mobilitaet.js" /* webpackChunkName: "component---src-pages-e-mobilitaet-js" */),
  "component---src-pages-elektroinstallationen-js": () => import("./../../../src/pages/elektroinstallationen.js" /* webpackChunkName: "component---src-pages-elektroinstallationen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-telefon-js": () => import("./../../../src/pages/internet-telefon.js" /* webpackChunkName: "component---src-pages-internet-telefon-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-photovoltaik-js": () => import("./../../../src/pages/photovoltaik.js" /* webpackChunkName: "component---src-pages-photovoltaik-js" */)
}

